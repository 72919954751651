<template>
  <div class="table-head">
    <a-form-model
      class="form-viewscreen"
      ref="viewscreenForm"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item label="Viewscreen" prop="name">
        <a-input @change="handleChangeLanguage" v-model="form.name" placeholder="Name" />
      </a-form-model-item>
      <div v-if="!loadingGetApplications" class="d-flex flex-column">
        <div class="float-right">
          <p class="font-size-16 font-weight-light">Select application</p>
        </div>
        <a-table
          :row-key="(record) => record.application_id"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            getCheckboxProps: (record) => ({
              props: {
                disabled: disabledApp(record.application_id),
              },
            }),
          }"
          :columns="appsColumns"
          :data-source="apps"
          :row-class-name="isRowDisabledClass"
        >
          <template slot="app" slot-scope="app, record">
            <a-tooltip :title="getDisabledRowTooltip(record.application_id)" placement="bottom">
            <div class="d-flex align-items-center">
              <a-icon type="android" v-if="record.platform.toLowerCase() === 'android'" class="text-success" />
              <a-icon type="apple" v-if="record.platform.toLowerCase() === 'ios'" class="text-primary" />
              <a-icon type="global" v-if="record.platform.toLowerCase() === 'web'" class="text-default" />
              <span class="ml-2">{{ record.name }}</span>
            </div>
          </a-tooltip>
          </template>
          <template slot="bundle" slot-scope="bundle, record">
            <a-tooltip :title="getDisabledRowTooltip(record.application_id)" placement="bottom">
              <span
                :disabled="disabledApp(record.application_id)"
                >{{ record.bundle_name }}</span
              >
          </a-tooltip>
          </template>
          <span slot="language" slot-scope="language, record">
            <a-tooltip :title="getDisabledRowTooltip(record.application_id)" placement="bottom">
            <a-select
              v-model="record.langs"
              mode="multiple"
              placeholder="All languages"
              :disabled="disabledApp(record.application_id)"
              style="width: 200px"
              @change="handleChangeLanguage"
              class="viewscreen-select"
            >
              <a-select-option v-for="lang in langs" :key="lang.id">
                {{ lang.desc }}
              </a-select-option>
            </a-select>
            <a-icon type="down" style="color: #bfbfbf" />
          </a-tooltip>
          </span>
        </a-table>
        <div v-if="!this.isFormValid" class="noAppsValid"><p>Please select at least one application</p></div>
      </div>

      <spinner v-else />
      <a-row class="footer-viewscreen">
        <a-col class="text-left" :span="6">
          <span v-if="modalTabData">
            <a-popconfirm title="Sure to delete?" @confirm="deleteViewscreen">
              <a href="javascript:;">
                <a-button type="danger" :loading="deleteLoading"
                  >Delete</a-button
                >
              </a>
            </a-popconfirm>
          </span>
        </a-col>
        <a-col class="text-right" :span="18">
          <a-button @click="closeModal"> Cancel </a-button>
          <a-button
            v-if="modalTabData"
            :loading="submitLoading"
            type="primary"
            @click="onSubmit"
            class="ml-2"
          >
            Save
          </a-button>
          <a-button
            v-else
            :loading="submitLoading"
            type="primary"
            @click="onSubmit"
            class="ml-2"
          >
            Add
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import MainService from '@/services/api/apiMainService'

const appsColumns = [
  {
    title: 'App',
    dataIndex: 'app',
    scopedSlots: { customRender: 'app' },
    customHeaderCell: (column) => ({
      class: 'table-header',
    }),
  },
  {
    title: 'Bundle ID',
    dataIndex: 'bundle',
    scopedSlots: { customRender: 'bundle' },
    customHeaderCell: (column) => ({
      class: 'table-header',
    }),
  },
  {
    title: 'Language',
    dataIndex: 'language',
    scopedSlots: { customRender: 'language' },
    customHeaderCell: (column) => ({
      class: 'table-header',
    }),
  },
]

export default {
  props: ['modalTabData', 'disabledAppsIds', 'disabledTabNames'],
  components: {
    spinner: () => import('../components/spinner.vue'),
  },
  data: () => ({
    loadingGetApplications: false,
    submitLoading: false,
    deleteLoading: false,
    form: {
      name: '',
      applications: [],
      languages: [],
    },
    rules: {
      name: [
        {
          required: true,
          message: 'This field is required',
          trigger: 'change',
        },
      ],
    },
    apps: [],
    langs: [],
    appsColumns,
    selectedRowKeys: [],
    selectedData: [],
    isFormValid: true,
  }),
  methods: {
    getLocalizations() {
      const url = '/admin/localizations'
      apiClient
        .get(url)
        .then((response) => {
          this.langs = response.data.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedData = this.getSelectedData()
      this.isFormValid = true
    },
    getSelectedData() {
      return this.apps
        .filter(app => this.selectedRowKeys.includes(app.application_id))
        .flatMap(app => {
          if (app.langs.length === 0) {
            return this.langs.map(lang => lang.id).map(defaultLangId => ({
              application_id: app.application_id,
              default_lang_id: defaultLangId,
            }))
          } else {
            return app.langs.map(lang => ({
              application_id: app.application_id,
              default_lang_id: lang,
            }))
          }
        })
    },

    handleChangeLanguage() {
      this.onSelectChange(this.selectedRowKeys)
    },
    tabDataFilling() {
      this.form.name = this.modalTabData.name
      this.selectedRowKeys = this.modalTabData.apps.map(app => app.application_id)

      this.selectedRowKeys.forEach(applicationId => {
        const matchedApp = this.modalTabData.apps.find(app => app.application_id === applicationId)
        if (matchedApp) {
          matchedApp.langs = matchedApp.langs.map(lang => lang.id)
        }
      })
    },
    async getApplications() {
      const url = '/admin/applications'
      this.loadingGetApplications = true
      try {
        await apiClient.get(url).then((response) => {
          const apps = []
          response.data.data.forEach((app) => {
            if (this.modalTabData) {
              this.modalTabData.apps.forEach(el => {
                if (el.application_id === app.application_id) {
                  app.langs = el.langs
                } else if (!app?.langs?.length) {
                  app.langs = []
                }
              })
            } else {
              app.langs = []
            }
            if (!this.checkApps(app.application_id)) {
              apps.push(app)
            }
          })

          this.apps = apps
        })
      } catch (e) {
        console.log(e, 'e')
      } finally {
        this.loadingGetApplications = false
      }
    },
    checkApps(appId) {
      const arrIds = [27, 30, 31, 32, 35, 38, 39]
      return arrIds.includes(appId)
    },
    disabledApp(appId) {
      if (this.modalTabData?.apps) {
        const currentAppsIds = this.modalTabData.apps.map(
          (app) => app.application_id,
        )
        return (
          this.disabledAppsIds.includes(appId) &&
          !currentAppsIds.includes(appId)
        )
      }
      return this.disabledAppsIds.includes(appId)
    },
    isRowDisabledClass(record) {
      const appId = record.application_id
      if (this.disabledApp(appId)) {
        return 'disabled-row'
      }
      return ''
    },
    getDisabledRowTooltip(applicationId) {
      if (this.disabledApp(applicationId)) {
        const disabledTab = this.disabledTabNames.find(tab => tab.appId === applicationId)
        if (disabledTab) {
          return `This option is not available, it has already been added to "${disabledTab.tabName}".`
        }
      }
      return null
    },
    onSubmit() {
      if (!this.selectedRowKeys.length) {
        this.isFormValid = false
        return false
      }
      this.$refs.viewscreenForm.validate((valid) => {
        if (valid) {
          this.isFormValid = true
          if (this.modalTabData) {
            this.updateViewscreen()
          } else {
            this.createViewscreen()
          }
        } else {
          return false
        }
      })
    },
    async createViewscreen() {
      const data = {
        name: this.form.name,
        applications: this.selectedData,
      }
      this.submitLoading = true
      try {
        await MainService.createTopTab(data)
          .then((res) => {
            this.$emit('create', res.data.data)
            this.notification('Successfully created')
            return res
          })
          .catch((error) => {
            console.log(error)
          })
      } finally {
        this.closeModal()
        this.submitLoading = false
      }
    },
    async updateViewscreen() {
      const oldAppsIds = this.modalTabData.apps.map((app) => app.application_id)
      const data = {
        name: this.form.name,
        applications: this.selectedData,
        // sections_ids: [],
      }
      this.submitLoading = true
      try {
        await MainService.updateTopTab(this.modalTabData.id, data)
          .then((res) => {
            const updatedTabData = res.data.data
            const newTabData = {
              id: updatedTabData.id,
              name: updatedTabData.name,
              apps: updatedTabData.apps,
              apps_ids: updatedTabData.apps.map((app) => app.application_id),
              sections: updatedTabData?.sections || [],
            }
            this.$emit('update', newTabData, oldAppsIds)
            this.notification('Successfully updated')
            return res
          })
          .catch((error) => {
            console.log(error)
          })
      } finally {
        this.closeModal()
        this.submitLoading = false
      }
    },
    async deleteViewscreen() {
      this.deleteLoading = true
      try {
        await MainService.deleteTopTab(this.modalTabData.id)
          .then((res) => {
            this.notification(res.data.data)
            return res
          })
          .catch((error) => {
            console.log(error)
          })
      } finally {
        this.closeModal()
        this.deleteLoading = false
        this.$emit('delete', this.modalTabData)
      }
    },
    notification(message, description = '', duration = 2) {
      this.$notification.success({
        message: message,
        description: description,
        duration: duration,
      })
    },
    closeModal() {
      this.$emit('close')
    },
  },
  mounted() {
    this.getApplications()
    this.getLocalizations()
    if (this.modalTabData) {
      this.tabDataFilling()
    }
  },
}
</script>

<style lang="scss">
.footer-viewscreen {
  margin-left: -24px !important;
  margin-right: -24px !important;
  border-top: 1px solid #d9d9d9;
  padding: 10px 24px 0 24px !important;
}

.table-head .ant-table-thead > tr > th {
  background-color: #fafafa !important;
  font-weight: 600 !important;
}
.disabled-row {
  background-color: #fafafa;
  color: #595959;
  font-weight: 400;
}
.viewscreen-select{
  .ant-select-selection {
    background-color: transparent !important;
    border: 0 !important;
  }
}

.checkbox-list {
  display: flex !important;
  flex-wrap: wrap;
  &__item {
    flex: 1 1 30%;
    max-width: 31%;
    padding: 5px 0 !important;
  }
}
.noAppsValid{
  color: #f5222e;
  clear: both;
  min-height: 22px;
  margin-top: -2px;
  font-size: 14px;
  line-height: 1.5;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
</style>
